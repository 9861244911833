import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import YellowBtn from '../../Common/components/Buttons/YellowBtn/YellowBtn';
import Title from "../../Common/components/Title/Title"
import { services_section1, listCheck } from "../../Common/Imgs/Image"
import "./services.css"

export default function Services() {
    const { t } = useTranslation();
    useEffect(() => {
        document.title = t("components.nav.pages.services") + " | BN STUDENTS";
        window.scrollTo(0, 0);
    }, [t]);
    return (
        <div className='services'>
            <div className='services_intro'>
                <div className='services-intro_left'>
                    <div className="services-intro_left__title">
                        <Title title={t("pages.services.ourServices.title")}></Title>
                    </div>
                    <ul>
                        <li><img src={listCheck} alt='check list' />{t("pages.services.ourServices.subtitle1")}</li>
                        <li><img src={listCheck} alt='check list' />{t("pages.services.ourServices.subtitle2")}</li>
                        <li><img src={listCheck} alt='check list' />{t("pages.services.ourServices.subtitle3")}</li>
                        <li><img src={listCheck} alt='check list' />{t("pages.services.ourServices.subtitle4")}</li>
                        <li><img src={listCheck} alt='check list' />{t("pages.services.ourServices.subtitle5")}</li>
                    </ul>
                    <div className="services-apply-link">
                        <NavLink className="services-apply-link" to="/contact">
                            <YellowBtn arrow={true}>{t("pages.universities.uniPage.button")}</YellowBtn>
                        </NavLink>
                    </div>
                </div>
                <img src={services_section1} alt='services background' className='services-intro_right' />
            </div>
            <div className='background-color-gray'>
                <div className='services_section reverse'>
                    <div className='services-section_content reverse'>
                        <hr />
                        <div className='services-content_content'>
                            <h1>{t("pages.services.admission.title")}</h1>
                            <p>{t("pages.services.admission.subtitle")}</p>
                            <div className="services-apply-link">
                                <NavLink className="services-apply-link" to="/contact">
                                    <YellowBtn arrow={true}>{t("pages.universities.uniPage.button")}</YellowBtn>
                                </NavLink>
                            </div>
                        </div>
                    </div>

                    <div className='services_empty'></div>
                </div>
            </div>
            <div className='services_section '>
                <div className='services-section_content '>
                    <hr />
                    <div className='services-content_content'>
                        <h1>{t("pages.services.pickUp.title")}</h1>
                        <p>{t("pages.services.pickUp.subtitle")}</p>
                        <div className="services-apply-link">
                            <NavLink className="services-apply-link" to="/contact">
                                <YellowBtn arrow={true}>{t("pages.universities.uniPage.button")}</YellowBtn>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className='services_empty'></div>
            </div>
            <div className='background-color-gray'>
                <div className='services_section reverse'>
                    <div className='services-section_content reverse'>
                        <hr />
                        <div className='services-content_content'>
                            <h1>{t("pages.services.certificate.title")}</h1>
                            <p>{t("pages.services.certificate.subtitle")}</p>
                            <div className="services-apply-link">
                                <NavLink className="services-apply-link" to="/contact">
                                    <YellowBtn arrow={true}>{t("pages.universities.uniPage.button")}</YellowBtn>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className='services_empty'></div>
                </div>
            </div>
            <div className='services_section '>
                <div className='services-section_content '>
                    <hr />
                    <div className='services-content_content'>
                        <h1>{t("pages.services.permit.title")}</h1>
                        <p>{t("pages.services.permit.subtitle")}</p>
                        <div className="services-apply-link">
                            <NavLink className="services-apply-link" to="/contact">
                                <YellowBtn arrow={true}>{t("pages.universities.uniPage.button")}</YellowBtn>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className='services_empty'></div>
            </div>
            <div className='background-color-gray'>
                <div className='services_section reverse'>
                    <div className='services-section_content reverse'>
                        <hr />
                        <div className='services-content_content'>
                            <h1>{t("pages.services.housing.title")}</h1>
                            <p>{t("pages.services.housing.subtitle")}</p>
                            <div className="services-apply-link">
                                <NavLink className="services-apply-link" to="/contact">
                                    <YellowBtn arrow={true}>{t("pages.universities.uniPage.button")}</YellowBtn>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className='services_empty'></div>
                </div>
            </div>
            <div className='services_section '>
                <div className='services-section_content '>
                    <hr />
                    <div className='services-content_content'>
                        <h1>{t("pages.services.transportation.title")}</h1>
                        <p>{t("pages.services.transportation.subtitle")}</p>
                        <div className="services-apply-link">
                            <NavLink className="services-apply-link" to="/contact">
                                <YellowBtn arrow={true}>{t("pages.universities.uniPage.button")}</YellowBtn>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className='services_empty'></div>
            </div>
        </div>
    )
}