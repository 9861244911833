import React from 'react'
import "./aboutUsValuesCard.css"

export default function AboutUsValuesCard({ title, content }) {
    return (
        <div className='about-us_values-card'>
            <h1>{title}</h1>
            <p>{content}</p>
        </div>
    )
}