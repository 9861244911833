import React from 'react'
import { useTranslation } from "react-i18next";
import UniCard from '../UniCard/UniCard';
import Universities from '../../../../Common/Data/universities';
import "./uniCards.css";

export default function UniCards({ country }) {
    const { t } = useTranslation();
    if (!Universities() || !Universities()[country] || Object.keys(Universities()[country]).length === 0) {
        return (
            <div className="uni-cards__error-message">
                <p>{t("pages.universities.nullMessage")}</p>
            </div>
        );
    }

    return (
        <div className="uni-cards__container">
            {Object.keys(Universities()[country]).map((key) => (
                <UniCard
                    key={key}
                    index={key}
                    img={Universities()[country][key].img}
                    title={Universities()[country][key].title}
                    price={Universities()[country][key].price}
                    lan={Universities()[country][key].lan}
                    slug={key}
                    country={country}
                />
            ))}
        </div>
    );
}