import { useEffect } from 'react'
import { useTranslation } from "react-i18next";

import Title from "../../Common/components/Title/Title"
import ContactFormHorizontal from '../../Common/components/ContactForm/ContactFormHorizontal';
import "./contact.css"

function Contact() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("components.nav.pages.contactus") + " | BN STUDENTS";
    window.scrollTo(0, 0);
  }, [t]);

  return (
    <div className="contactus__container">
      <div className='contactus__title__container'>
        <Title
          title={t("pages.contactus.title")}
        />
      </div>

      <div className="headers__container">
        <h1>{t("pages.contactus.subtitle1")}</h1>
        <h1>{t("pages.contactus.subtitle2")}</h1>
      </div>
      <div className="contact-form-horizontal__container">
        <ContactFormHorizontal />
      </div>
    </div>
  );
}
export default Contact;
