import React from 'react'
import useDirectionBasedStyle from '../../../../hooks/DirectionBasedStyle/useDirectionBasedStyle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import "./yellowBtn.css";

export default function YellowBtn({ arrow, children }) {
    const rtlRef = useDirectionBasedStyle(true);
    return (
        <button className="yellowBtn" role="button" >
            <div>{children}</div>
            <div ref={rtlRef}>
                {arrow ?
                    <FontAwesomeIcon icon={faArrowRight} />
                    :
                    null
                }
            </div>
        </button>
    )
}