import React from 'react'
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';

// IMPORTING IMAGES/ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ar_flag, en_flag } from '../../Imgs/Image';

import './langDropDown.css'

const selectedLanguageStyle = {
    backgroundColor: '#F0A758',
    color: 'white'
}

export default function LangDropDown({ languages, customOnClick }) {
    const { t, i18n } = useTranslation();
    const currentLanguageCode = Cookies.get('i18next') || 'en';
    // Function to handle click event
    const handleClick = (lang) => {
        i18n.changeLanguage(lang);
        if (customOnClick) {
            customOnClick();
        }
    };

    return (
        <div className='lang-dropdown-menu'>
            <div className='lang-dropdown-menu__buttons'>
                <div className="icon" >
                    {currentLanguageCode == 'ar' ?
                        <NavLink className="lang-drop-down__container" >
                            <img src={ar_flag} alt="arabic flag" className='lang_icon' />
                            <span>العربية</span>
                            <FontAwesomeIcon icon={faChevronDown} />
                        </NavLink>
                        :
                        <NavLink className="lang-drop-down__container">
                            <img src={en_flag} alt="english flag" />
                            <span>English</span>
                            <FontAwesomeIcon icon={faChevronDown} />
                        </NavLink>
                    }
                </div>
                <div className="content">
                    {languages.map(lang => (currentLanguageCode === lang ?
                        <div className='item ' onClick={() => handleClick(lang)} style={selectedLanguageStyle} key={lang}>
                            {currentLanguageCode == 'ar' ?
                                <img src={ar_flag} alt="arabic flag" className='lang_icon' />
                                :
                                <img src={en_flag} alt="english flag" className='lang_icon' />
                            }
                            {t("components.language-drop-down-menu." + lang)}
                            <FontAwesomeIcon icon={faChevronLeft} />

                        </div>
                        :
                        <div className='item ' onClick={() => handleClick(lang)} key={lang}>
                            {currentLanguageCode == 'en' ?
                                <img src={ar_flag} alt="arabic flag" className='lang_icon' />
                                :
                                <img src={en_flag} alt="english flag" className='lang_icon' />
                            }
                            {t("components.language-drop-down-menu." + lang)}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}