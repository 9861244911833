import { Outlet } from "react-router-dom";
import Nav from "../Common/Nav/Nav";
import Footer from "../Common/components/Footer/Footer";

function Router() {
  return (
    <>
      <Nav />
      <main>
        <Outlet />
        <Footer />
      </main>
    </>
  );
}

export default Router;
