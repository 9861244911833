import React from 'react'
import { useTranslation } from "react-i18next";

import Title from '../Title/Title'
import HoverCard from '../Cards/HoverCard/HoverCard'
import {
    services_admission_logo,
    services_support_logo,
    services_consultation_logo
} from "../../Imgs/Image"
import "./studentServices.css"

export default function StudentServices() {
    const { t } = useTranslation();

    return (
        <div className="student-services-section">
            <div className="title">
                <Title title={t("components.studentServices.title")} />
                <p>{t("components.studentServices.subtitle")}</p>
            </div>
            <div className="cards-container">
                <HoverCard
                    link={"services"}
                    linkTitle={t("components.studentServices.moreInfo")}
                    img={services_support_logo}
                    title={t("components.studentServices.supportCard.title")}
                    content={t("components.studentServices.supportCard.subtitle")} />
                <HoverCard
                    link={"services"}
                    linkTitle={t("components.studentServices.moreInfo")}
                    img={services_admission_logo}
                    title={t("components.studentServices.admissionCard.title")}
                    content={t("components.studentServices.admissionCard.subtitle")} />
                <HoverCard
                    link={"services"}
                    linkTitle={t("components.studentServices.moreInfo")}
                    img={services_consultation_logo}
                    title={t("components.studentServices.consultationCard.title")}
                    content={t("components.studentServices.consultationCard.subtitle")} />
            </div>
        </div>
    )
}