import { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { Trans } from 'react-i18next'

import Title from "../../Common/components/Title/Title"
import BeOurAgentForm from '../../Common/components/BeOurAgentForm/BeOurAgentForm';
import "./beOurAgent.css"

function BeOurAgent() {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t("components.nav.pages.beouragent") + " | BN STUDENTS";
        window.scrollTo(0, 0);
    }, [t]);

    return (
        <div className="beouragent__container">

            <div className='beouragent__title__container'>
                <Title
                    title={t("pages.beouragent.title")}

                />
            </div>
            <div className="headers__container">
                <h1>
                    <Trans
                        i18nKey="pages.beouragent.subtitle"
                        components={{ 1: <span /> }}
                    />
                </h1>
            </div>
            <div className="beouragent-form__container">
                <BeOurAgentForm />
            </div>
        </div>
    );
}
export default BeOurAgent;