import "./title.css";

function Title(props) {
  return (
    <div className="dash-title">
      {props.title && <div className="dash-title__text">
        <div className="dash-title__line"></div>
        <h1>{props.title}</h1>
      </div>}
      {props.content && <div className="dash-title__content">
        <div className="dash-title__line"></div>
        <p>{props.content}</p>
      </div>}
    </div>
  );
}

export default Title;
