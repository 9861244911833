import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import useEmblaCarousel from 'embla-carousel-react'
import './carousel.css'

export default function Carousel({ icons, divs, dotStyle, dotCount = 3, Loop = false, DragFree = true }) {
    // const { t } = useTranslation();
    // const [direction, setDirection] = useState(document.body.dir.toString());

    // const [emblaRef, emblaApi] = useEmblaCarousel({ loop: Loop, dragFree: DragFree, direction: direction });
    // useEffect(() => {
    //     console.log(direction)
    //     setDirection(document.body.dir.toString())
    // }, [t, emblaApi])
    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: Loop, dragFree: DragFree, direction: 'ltr' });
    useEffect(() => {
    }, [emblaApi])


    return (
        <div className="carousel" ref={emblaRef}>
            <div className="carousel__container">
                {icons != null ? (
                    icons.map((item, index) => (
                        <img src={item} className="carousel__slide" alt='University Icon' key={index} />
                    ))
                ) : (
                    divs.map((div, index) => (
                        <div className="carousel__slide" key={index}>
                            {div}
                        </div>
                    ))
                )}
            </div>
            <div className="carousel__dots" style={dotStyle}>
                {Array.from({ length: dotCount }).map((_, index) => (
                    <span className="carousel__dot" key={index}></span>
                ))}
            </div>
        </div>
    )
}