import React, { useState } from 'react';
import './dropDownInput.css';

function DropDownInput({ options, selectedOption, onSelect }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        onSelect(option);
        setIsOpen(false);
    };

    return (
        <div className="dropdown">
            <div className="dropdown-selected" onClick={toggleDropdown}>
                {selectedOption}
                <span className="dropdown-arrow">&#9660;</span>
            </div>
            {isOpen && (
                <div className="dropdown-options">
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className="dropdown-option"
                            onClick={() => handleOptionClick(option)}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default DropDownInput;
