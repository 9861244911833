import React, { useState, useEffect } from 'react'
import PhoneInputParser from '../PhoneInput/PhoneInputParser';
import { useTranslation } from "react-i18next";
import emailjs from '@emailjs/browser';
import "./beOurAgentForm.css"

export default function BeOurAgentForm() {
    const { t } = useTranslation();

    const initialFormData = {
        fullName: '',
        phoneNumber: '',
        email: '',
        language: '',
        companyWebsite: '',
        companyAddress: '',
        source: ''
    };
    const [formData, setFormData] = useState(initialFormData);
    const [formStatus, setFormStatus] = React.useState(t("components.beOurAgentForm.button"))

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    useEffect(() => {
        setFormStatus(t("components.beOurAgentForm.button"))
    }, [t])
    const handleSubmit = (e) => {
        setFormStatus(t("components.beOurAgentForm.loading"))
        e.preventDefault();
        // Handle form submission
        emailjs.send("service_4x3eo1m", "template_81c3mra", {
            to_name: "BN Student (Be Our Agent Page)",
            from_fullName: formData.fullName,
            from_name: formData.fullName,
            from_email: formData.email,
            from_phone: formData.phoneNumber,
            communication_lang: formData.language,
            company_website: formData.companyWebsite,
            company_address: formData.companyAddress,
            hear_about_us: formData.source,
            reply_to: "info@tovmo.com",
        }, {
            publicKey: '5GcS1AJBSrKCOKCYz',
        }).then((result) => {
            // console.log(result.text);
            alert('Inquiry submitted successfully!');
            setFormData(initialFormData); // Reset form data
            setFormStatus(t("components.beOurAgentForm.button"))
        }, (error) => {
            // console.error(error.text);
            alert('Failed to send the inquiry. Please try again later.');
        });
        e.target.reset();
        // console.log('Form data:', formData);
    };


    return (
        <form onSubmit={handleSubmit} className="beouragent-form">
            <div className="input-container">
                <label>
                    {t("components.beOurAgentForm.name")}
                    <input
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    {t("components.beOurAgentForm.email")}
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    {t("components.beOurAgentForm.phone")}
                    <PhoneInputParser
                        placeholder={t("components.beOurAgentForm.phone")}
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={formData => handleChange({ target: { value: formData, name: 'phoneNumber' } })}
                        required={true}
                    />
                </label>
                <label>
                    {t("components.beOurAgentForm.language")}
                    <select
                        name="language"
                        id="language"
                        value={formData.language}
                        onChange={handleChange}
                        required
                    >
                        <option value="" disabled defaultValue>{t("components.beOurAgentForm.selectLang")}</option>
                        <option value="English">English</option>
                        <option value="Arabic">Arabic</option>
                        <option value="Turkish">Turkish</option>
                    </select>
                </label>
                <label>
                    {t("components.beOurAgentForm.companyWebsite")}
                    <input
                        name="companyWebsite"
                        value={formData.companyWebsite}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    {t("components.beOurAgentForm.companyAddress")}
                    <input
                        name="companyAddress"
                        value={formData.companyAddress}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    {t("components.beOurAgentForm.how.title")}
                    <select
                        name="source"
                        id="source"
                        value={formData.source}
                        onChange={handleChange}
                        required
                    >
                        <option value="" disabled defaultValue>{t("components.beOurAgentForm.how.title")}</option>
                        <option value="Social Media">{t("components.beOurAgentForm.how.social")}</option>
                        <option value="Email">{t("components.beOurAgentForm.how.email")}</option>
                        <option value="Acquaintance">{t("components.beOurAgentForm.how.acquaintance")}</option>
                    </select>
                </label>
                <button type="submit" className='beouragent-form__button'>{formStatus}</button>
            </div>

        </form>
    );
}
