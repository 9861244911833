import React from 'react'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import useDirectionBasedStyle from '../../../../hooks/DirectionBasedStyle/useDirectionBasedStyle';
import { logo } from '../../../../Common/Imgs/Image';
import RightArrowBtn from '../../../../Common/components/Buttons/RightArrowBtn/RightArrowBtn';

import "./uniCard.css";

export default function UniCard({ index, img, title, price, lan, slug, country }) {
    const { t } = useTranslation();
    return (
        <div id={index} className='uni-card'>
            <div className="uni-card__images__container">
                <div className="uni-card__top-left-logo">
                    <img src={logo} alt="logo" />
                </div>
                <img src={img} alt={index} className="uni-img" />
            </div>
            <div className="uni-card__content">
                <div className="left">
                    <div>{title}</div>
                    <div>{t("pages.universities.uniCards.startingFrom")} $<span>{price}</span></div>
                    <div>{lan}</div>
                </div>
                <div className="right">
                    <div ref={useDirectionBasedStyle(true)}>
                        <Link to={`/universities/${country}/${slug}`}>
                            <RightArrowBtn
                                fontSize={45}
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}