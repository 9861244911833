// IMPORTING LIBRARIES
import React from 'react'

// IMPORTING IMAGES/ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons'

// IMPORTING STYLE
import './rightArrowBtn.css'

export default function RightArrowBtn({ fontSize }) {
    const buttonSize = `${fontSize * (2 / 3)}px`;
    const buttonStyle = {
        width: buttonSize,
        height: buttonSize,
        fontSize: fontSize,
    };

    return (
        <div className='right-arrow-button' style={buttonStyle}>
            <FontAwesomeIcon icon={faCircleArrowRight} />
        </div>
    )
}
