import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
import YellowBtn from '../../Common/components/Buttons/YellowBtn/YellowBtn';
import Title from "../../Common/components/Title/Title"
import AboutUsValuesSection from './Sections/AboutUsValuesSection/AboutUsValuesSection'
import StudentServices from '../../Common/components/StudentServices/StudentServices'
import { about_us_header_img } from '../../Common/Imgs/Image'
import "./about.css"

export default function About() {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t("components.nav.pages.aboutus") + " | BN STUDENTS";
        window.scrollTo(0, 0);
    }, [t]);
    return (
        <div className='about-us'>
            <div className='company-mission'>
                <div className='left'>
                    <Title title={t("pages.aboutus.title")} />
                    <p>{t("pages.aboutus.subtitle")}</p>
                    <NavLink className="about-us-apply-link" to="/contact">
                        <YellowBtn arrow={true}>{t("pages.universities.uniPage.button")}</YellowBtn>
                    </NavLink>
                </div>
                <div className="right">
                    <img src={about_us_header_img} />
                </div>
            </div>
            <AboutUsValuesSection />
            <StudentServices />
        </div>
    )
}