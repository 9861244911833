import React from 'react'
import { useTranslation } from "react-i18next";

import AboutUsValuesCard from '../../Components/AboutUsValuesCard/AboutUsValuesCard'
import "./aboutUsValuesSection.css"

export default function AboutUsValuesSection() {
    const { t } = useTranslation();

    return (
        <div className='aboutus-values-section__background'>
            <div className='aboutus-values-section'>
                <p className='title'>{t("pages.aboutus.valuesSection.title")}</p>
                <hr />
                <div className='aboutus-values-section__cards'>
                    <AboutUsValuesCard title={t("pages.aboutus.valuesSection.confidentiality.title")} content={t("pages.aboutus.valuesSection.confidentiality.subtitle")} />
                    <div className="separator"></div>
                    <AboutUsValuesCard title={t("pages.aboutus.valuesSection.integrity.title")} content={t("pages.aboutus.valuesSection.integrity.subtitle")} />
                    <div className="separator"></div>
                    <AboutUsValuesCard title={t("pages.aboutus.valuesSection.transparency.title")} content={t("pages.aboutus.valuesSection.transparency.subtitle")} />
                    <AboutUsValuesCard title={t("pages.aboutus.valuesSection.nondiscrimination.title")} content={t("pages.aboutus.valuesSection.nondiscrimination.subtitle")} />
                    <div className="separator"></div>
                    <AboutUsValuesCard title={t("pages.aboutus.valuesSection.competence.title")} content={t("pages.aboutus.valuesSection.competence.subtitle")} />
                </div>
            </div>
        </div>
    )
}