import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import emailjs from '@emailjs/browser';
import PhoneInputParser from '../PhoneInput/PhoneInputParser';
import "./contactFormHorizontal.css"


export default function ContactFormHorizontal() {
    const { t } = useTranslation();
    const initialFormData = {
        fullName: '',
        phoneNumber: '',
        email: '',
        language: '',
        inquiry: ''
    };
    const [formData, setFormData] = useState(initialFormData);
    const [formStatus, setFormStatus] = React.useState(t("components.contactForm.button"))

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    useEffect(() => {
        setFormStatus(t("components.contactForm.button"))
    }, [t])
    const handleSubmit = (e) => {
        setFormStatus(t("components.contactForm.loading"))
        e.preventDefault();
        // Handle form submission
        emailjs.send("service_4x3eo1m", "template_81c3mra", {
            to_name: "BN Student (Contact Us Page)",
            from_fullName: formData.fullName,
            from_name: formData.fullName,
            from_email: formData.email,
            communication_lang: formData.language,
            from_phone: formData.phoneNumber,
            message: formData.inquiry,
            reply_to: "info@tovmo.com",
        }, {
            publicKey: '5GcS1AJBSrKCOKCYz',
        }).then((result) => {
            // console.log(result.text);
            alert('Inquiry submitted successfully!');
            setFormData(initialFormData); // Reset form data
            setFormStatus(t("components.contactForm.button"))

        }, (error) => {
            // console.error(error.text);
            alert('Failed to send the inquiry. Please try again later.');
        });

        e.target.reset();
        // console.log('Form data:', formData);
    }
    return (
        <form onSubmit={handleSubmit} className="contact-form-horizontal">
            <div className="input-container">
                <label>
                    {t("components.contactForm.name")}
                    <input
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    {t("components.contactForm.email")}
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    {t("components.contactForm.phone")}
                    <PhoneInputParser
                        placeholder={t("components.contactForm.phone")}
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={formData => handleChange({ target: { value: formData, name: 'phoneNumber' } })}
                        required={true}
                    />
                </label>
                <label>
                    {t("components.contactForm.language")}
                    <select
                        name="language"
                        id="language"
                        value={formData.language}
                        onChange={handleChange}
                        required
                    >
                        <option value="" disabled defaultValue>{t("components.contactForm.selectLang")}</option>
                        <option value="English">English</option>
                        <option value="Arabic">Arabic</option>
                        <option value="Turkish">Turkish</option>
                    </select>
                </label>
                <label>
                    {t("components.contactForm.inquiry")}
                    <textarea
                        name="inquiry"
                        value={formData.inquiry}
                        onChange={handleChange}
                        required
                    />
                </label>
                <button type="submit" className='contact-form-horizontal__button'>{formStatus}</button>
            </div>

        </form>
    );
}
