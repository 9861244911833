import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';

import Title from '../../../../Common/components/Title/Title';
import YellowBtn from '../../../../Common/components/Buttons/YellowBtn/YellowBtn';
import Spec from '../Spec/Spec';

// import { universities } from "../../../../Common/Data/universities"
import Universities from '../../../../Common/Data/universities';
import "./uniPage.css";
import "./uniPageResponsive.css";

export default function UniPage() {
    const { t } = useTranslation();
    const { slug } = useParams();
    const { country } = useParams();
    let countryName = Universities()[country][slug].location.country;
    let universityName = Universities()[country][slug].title;

    useEffect(() => {
        document.title = universityName + " | BN STUDENTS";
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className='uni-page__container'>
                <div className="uni-page__title-container">
                    <Title title={t("pages.universities.uniPage.title", { countryName, universityName })} />
                </div>
                <div className='uni-page__card'>
                    <div className="img">
                        <img src={Universities()[country][slug].img} alt={Universities()[country][slug].title} />
                    </div>
                    <div className="content">
                        <div><span>{t("pages.universities.uniPage.uniType")}:</span> <span>{Universities()[country][slug].uni_type}</span></div>
                        <div><span>{t("pages.universities.uniPage.location")}:</span> <span>{Universities()[country][slug].location.city}</span></div>
                        <div><span>{t("pages.universities.uniPage.globalRank")}:</span> <span>{Universities()[country][slug].global_rank}</span></div>
                        <div><span>{t("pages.universities.uniPage.localRank")}:</span> <span>{Universities()[country][slug].local_rank}</span></div>
                        <div><span>{t("pages.universities.uniPage.studyPrograms")}:</span> <span>{Universities()[country][slug].study_programs}</span></div>
                        <div><span>{t("pages.universities.uniPage.studyLanguage")}:</span> <span>{Universities()[country][slug].lan}</span></div>
                        <div className='apply-btn'>
                            <NavLink className="apply-link" to="/contact">
                                <YellowBtn arrow={true}>{t("pages.universities.uniPage.button")}</YellowBtn>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className='description'>
                    {Universities()[country][slug].description}
                </div>
            </div>
            <Spec uniName={Universities()[country][slug].title} />
        </>
    );
}