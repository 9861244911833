import { useTranslation } from "react-i18next";
import {
    BauUni,
    IsuUni,
    OkanUni,
    BilgiUni,
    GelisimUni,
    UskudarUni,
    AltinbasUni,
    OzyeginUni,
    AydinUni,
    NisantasiUni,
    MedipolUni,
    AtlasUni,
    KentUni,
    ArelUni,
    GedikUni,
    NearEastUni,
    BahcesehirCypUni,
    KyreniaUni,
    EasternMediterraneanUni
} from "../Imgs/Image";

export default function Universities() {
    const { t } = useTranslation();
    const universities = {
        "tr": {
            "bahcesehir-university": {
                img: BauUni,
                title: t("universities.tr.bahcesehir-university.title"),
                price: t("universities.tr.bahcesehir-university.price"),
                uni_type: t("universities.tr.bahcesehir-university.uni_type"),
                lan: t("universities.tr.bahcesehir-university.lan"),
                location: {
                    country: t("universities.tr.bahcesehir-university.location.country"),
                    city: t("universities.tr.bahcesehir-university.location.city")
                },
                local_rank: t("universities.tr.bahcesehir-university.local_rank"),
                global_rank: t("universities.tr.bahcesehir-university.global_rank"),
                study_programs: t("universities.tr.bahcesehir-university.study_programs"),
                description: t("universities.tr.bahcesehir-university.description")
            },
            "istinye-university": {
                img: IsuUni,
                title: t("universities.tr.istinye-university.title"),
                price: t("universities.tr.istinye-university.price"),
                uni_type: t("universities.tr.istinye-university.uni_type"),
                lan: t("universities.tr.istinye-university.lan"),
                location: {
                    country: t("universities.tr.istinye-university.location.country"),
                    city: t("universities.tr.istinye-university.location.city")
                },
                local_rank: t("universities.tr.istinye-university.local_rank"),
                global_rank: t("universities.tr.istinye-university.global_rank"),
                study_programs: t("universities.tr.istinye-university.study_programs"),
                description: t("universities.tr.istinye-university.description")
            },
            "okan-university": {
                img: OkanUni,
                title: t("universities.tr.okan-university.title"),
                price: t("universities.tr.okan-university.price"),
                uni_type: t("universities.tr.okan-university.uni_type"),
                lan: t("universities.tr.okan-university.lan"),
                location: {
                    country: t("universities.tr.okan-university.location.country"),
                    city: t("universities.tr.okan-university.location.city")
                },
                local_rank: t("universities.tr.okan-university.local_rank"),
                global_rank: t("universities.tr.okan-university.global_rank"),
                study_programs: t("universities.tr.okan-university.study_programs"),
                description: t("universities.tr.okan-university.description")
            },
            "bilgi-university": {
                img: BilgiUni,
                title: t("universities.tr.bilgi-university.title"),
                price: t("universities.tr.bilgi-university.price"),
                uni_type: t("universities.tr.bilgi-university.uni_type"),
                lan: t("universities.tr.bilgi-university.lan"),
                location: {
                    country: t("universities.tr.bilgi-university.location.country"),
                    city: t("universities.tr.bilgi-university.location.city")
                },
                local_rank: t("universities.tr.bilgi-university.local_rank"),
                global_rank: t("universities.tr.bilgi-university.global_rank"),
                study_programs: t("universities.tr.bilgi-university.study_programs"),
                description: t("universities.tr.bilgi-university.description")
            },
            "gelisim-university": {
                img: GelisimUni,
                title: t("universities.tr.gelisim-university.title"),
                price: t("universities.tr.gelisim-university.price"),
                uni_type: t("universities.tr.gelisim-university.uni_type"),
                lan: t("universities.tr.gelisim-university.lan"),
                location: {
                    country: t("universities.tr.gelisim-university.location.country"),
                    city: t("universities.tr.gelisim-university.location.city")
                },
                local_rank: t("universities.tr.gelisim-university.local_rank"),
                global_rank: t("universities.tr.gelisim-university.global_rank"),
                study_programs: t("universities.tr.gelisim-university.study_programs"),
                description: t("universities.tr.gelisim-university.description")
            },
            "uskudar-university": {
                img: UskudarUni,
                title: t("universities.tr.uskudar-university.title"),
                price: t("universities.tr.uskudar-university.price"),
                uni_type: t("universities.tr.uskudar-university.uni_type"),
                lan: t("universities.tr.uskudar-university.lan"),
                location: {
                    country: t("universities.tr.uskudar-university.location.country"),
                    city: t("universities.tr.uskudar-university.location.city")
                },
                local_rank: t("universities.tr.uskudar-university.local_rank"),
                global_rank: t("universities.tr.uskudar-university.global_rank"),
                study_programs: t("universities.tr.uskudar-university.study_programs"),
                description: t("universities.tr.uskudar-university.description")
            },
            "altinbas-university": {
                img: AltinbasUni,
                title: t("universities.tr.altinbas-university.title"),
                price: t("universities.tr.altinbas-university.price"),
                uni_type: t("universities.tr.altinbas-university.uni_type"),
                lan: t("universities.tr.altinbas-university.lan"),
                location: {
                    country: t("universities.tr.altinbas-university.location.country"),
                    city: t("universities.tr.altinbas-university.location.city")
                },
                local_rank: t("universities.tr.altinbas-university.local_rank"),
                global_rank: t("universities.tr.altinbas-university.global_rank"),
                study_programs: t("universities.tr.altinbas-university.study_programs"),
                description: t("universities.tr.altinbas-university.description")
            },
            "ozyegin-university": {
                img: OzyeginUni,
                title: t("universities.tr.ozyegin-university.title"),
                price: t("universities.tr.ozyegin-university.price"),
                uni_type: t("universities.tr.ozyegin-university.uni_type"),
                lan: t("universities.tr.ozyegin-university.lan"),
                location: {
                    country: t("universities.tr.ozyegin-university.location.country"),
                    city: t("universities.tr.ozyegin-university.location.city")
                },
                local_rank: t("universities.tr.ozyegin-university.local_rank"),
                global_rank: t("universities.tr.ozyegin-university.global_rank"),
                study_programs: t("universities.tr.ozyegin-university.study_programs"),
                description: t("universities.tr.ozyegin-university.description")
            },
            "aydin-university": {
                img: AydinUni,
                title: t("universities.tr.aydin-university.title"),
                price: t("universities.tr.aydin-university.price"),
                uni_type: t("universities.tr.aydin-university.uni_type"),
                lan: t("universities.tr.aydin-university.lan"),
                location: {
                    country: t("universities.tr.aydin-university.location.country"),
                    city: t("universities.tr.aydin-university.location.city")
                },
                local_rank: t("universities.tr.aydin-university.local_rank"),
                global_rank: t("universities.tr.aydin-university.global_rank"),
                study_programs: t("universities.tr.aydin-university.study_programs"),
                description: t("universities.tr.aydin-university.description")
            },
            "nisantasi-university": {
                img: NisantasiUni,
                title: t("universities.tr.nisantasi-university.title"),
                price: t("universities.tr.nisantasi-university.price"),
                uni_type: t("universities.tr.nisantasi-university.uni_type"),
                lan: t("universities.tr.nisantasi-university.lan"),
                location: {
                    country: t("universities.tr.nisantasi-university.location.country"),
                    city: t("universities.tr.nisantasi-university.location.city")
                },
                local_rank: t("universities.tr.nisantasi-university.local_rank"),
                global_rank: t("universities.tr.nisantasi-university.global_rank"),
                study_programs: t("universities.tr.nisantasi-university.study_programs"),
                description: t("universities.tr.nisantasi-university.description")
            },
            "medipol-university": {
                img: MedipolUni,
                title: t("universities.tr.medipol-university.title"),
                price: t("universities.tr.medipol-university.price"),
                uni_type: t("universities.tr.medipol-university.uni_type"),
                lan: t("universities.tr.medipol-university.lan"),
                location: {
                    country: t("universities.tr.medipol-university.location.country"),
                    city: t("universities.tr.medipol-university.location.city")
                },
                local_rank: t("universities.tr.medipol-university.local_rank"),
                global_rank: t("universities.tr.medipol-university.global_rank"),
                study_programs: t("universities.tr.medipol-university.study_programs"),
                description: t("universities.tr.medipol-university.description")
            },
            "atlas-university": {
                img: AtlasUni,
                title: t("universities.tr.atlas-university.title"),
                price: t("universities.tr.atlas-university.price"),
                uni_type: t("universities.tr.atlas-university.uni_type"),
                lan: t("universities.tr.atlas-university.lan"),
                location: {
                    country: t("universities.tr.atlas-university.location.country"),
                    city: t("universities.tr.atlas-university.location.city")
                },
                local_rank: t("universities.tr.atlas-university.local_rank"),
                global_rank: t("universities.tr.atlas-university.global_rank"),
                study_programs: t("universities.tr.atlas-university.study_programs"),
                description: t("universities.tr.atlas-university.description")
            },
            "kent-university": {
                img: KentUni,
                title: t("universities.tr.kent-university.title"),
                price: t("universities.tr.kent-university.price"),
                uni_type: t("universities.tr.kent-university.uni_type"),
                lan: t("universities.tr.kent-university.lan"),
                location: {
                    country: t("universities.tr.kent-university.location.country"),
                    city: t("universities.tr.kent-university.location.city")
                },
                local_rank: t("universities.tr.kent-university.local_rank"),
                global_rank: t("universities.tr.kent-university.global_rank"),
                study_programs: t("universities.tr.kent-university.study_programs"),
                description: t("universities.tr.kent-university.description")
            },
            "arel-university": {
                img: ArelUni,
                title: t("universities.tr.arel-university.title"),
                price: t("universities.tr.arel-university.price"),
                uni_type: t("universities.tr.arel-university.uni_type"),
                lan: t("universities.tr.arel-university.lan"),
                location: {
                    country: t("universities.tr.arel-university.location.country"),
                    city: t("universities.tr.arel-university.location.city")
                },
                local_rank: t("universities.tr.arel-university.local_rank"),
                global_rank: t("universities.tr.arel-university.global_rank"),
                study_programs: t("universities.tr.arel-university.study_programs"),
                description: t("universities.tr.arel-university.description")
            },
            "gedik-university": {
                img: GedikUni,
                title: t("universities.tr.arel-university.title"),
                price: t("universities.tr.arel-university.price"),
                uni_type: t("universities.tr.arel-university.uni_type"),
                lan: t("universities.tr.arel-university.lan"),
                location: {
                    country: t("universities.tr.arel-university.location.country"),
                    city: t("universities.tr.arel-university.location.city")
                },
                local_rank: t("universities.tr.arel-university.local_rank"),
                global_rank: t("universities.tr.arel-university.global_rank"),
                study_programs: t("universities.tr.arel-university.study_programs"),
                description: t("universities.tr.arel-university.description")
            },
        },
        "cy": {
            "near-east-university": {
                img: NearEastUni,
                title: t("universities.cy.near-east-university.title"),
                price: t("universities.cy.near-east-university.price"),
                uni_type: t("universities.cy.near-east-university.uni_type"),
                lan: t("universities.cy.near-east-university.lan"),
                location: {
                    country: t("universities.cy.near-east-university.location.country"),
                    city: t("universities.cy.near-east-university.location.city")
                },
                local_rank: t("universities.cy.near-east-university.local_rank"),
                global_rank: t("universities.cy.near-east-university.global_rank"),
                study_programs: t("universities.cy.near-east-university.study_programs"),
                description: t("universities.cy.near-east-university.description")
            },
            "bahcesehir-university-cyprus": {
                img: BahcesehirCypUni,
                title: t("universities.cy.bahcesehir-university-cyprus.title"),
                price: t("universities.cy.bahcesehir-university-cyprus.price"),
                uni_type: t("universities.cy.bahcesehir-university-cyprus.uni_type"),
                lan: t("universities.cy.bahcesehir-university-cyprus.lan"),
                location: {
                    country: t("universities.cy.bahcesehir-university-cyprus.location.country"),
                    city: t("universities.cy.bahcesehir-university-cyprus.location.city")
                },
                local_rank: t("universities.cy.bahcesehir-university-cyprus.local_rank"),
                global_rank: t("universities.cy.bahcesehir-university-cyprus.global_rank"),
                study_programs: t("universities.cy.bahcesehir-university-cyprus.study_programs"),
                description: t("universities.cy.bahcesehir-university-cyprus.description")
            },
            "university-of-kyrenia": {
                img: KyreniaUni,
                title: t("universities.cy.university-of-kyrenia.title"),
                price: t("universities.cy.university-of-kyrenia.price"),
                uni_type: t("universities.cy.university-of-kyrenia.uni_type"),
                lan: t("universities.cy.university-of-kyrenia.lan"),
                location: {
                    country: t("universities.cy.university-of-kyrenia.location.country"),
                    city: t("universities.cy.university-of-kyrenia.location.city")
                },
                local_rank: t("universities.cy.university-of-kyrenia.local_rank"),
                global_rank: t("universities.cy.university-of-kyrenia.global_rank"),
                study_programs: t("universities.cy.university-of-kyrenia.study_programs"),
                description: t("universities.cy.university-of-kyrenia.description")
            },
            "eastern-mediterranean-university": {
                img: EasternMediterraneanUni,
                title: t("universities.cy.eastern-mediterranean-university.title"),
                price: t("universities.cy.eastern-mediterranean-university.price"),
                uni_type: t("universities.cy.eastern-mediterranean-university.uni_type"),
                lan: t("universities.cy.eastern-mediterranean-university.lan"),
                location: {
                    country: t("universities.cy.eastern-mediterranean-university.location.country"),
                    city: t("universities.cy.eastern-mediterranean-university.location.city")
                },
                local_rank: t("universities.cy.eastern-mediterranean-university.local_rank"),
                global_rank: t("universities.cy.eastern-mediterranean-university.global_rank"),
                study_programs: t("universities.cy.eastern-mediterranean-university.study_programs"),
                description: t("universities.cy.eastern-mediterranean-university.description")
            },
        },
        "ru": {}
    };
    return universities
}

