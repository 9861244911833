import { useEffect, useRef } from 'react'
import { useTranslation } from "react-i18next";
import "./useDirectionBasedStyle.css";

/*
    A function to add styles depending 
    on the direction of the document.
*/

/*
    The first 'mirror' parameter indicates wether a mirror for an element should be applied or not.
    The second 'style' parameter indicates the style that should be applied to the element,
    you can either pass a css class name to be applied to the element or you can pass the whole style as a variable.  
*/
export default function useDirectionBasedStyle(mirror, style) {
    const { t } = useTranslation();
    const directionRef = useRef(null);
    useEffect(() => {
        if (mirror) {
            if (typeof style === 'string' || style == null) {
                directionRef.current.className = document.body.dir.toString() + " " + style;
            } else if (typeof style === 'object') {
                Object.assign(directionRef.current.style, style);
                directionRef.current.className = directionRef.current.className + " " + document.body.dir.toString();
            }
        } else {
            if (typeof style === 'string' || style == null) {
                directionRef.current.className = style;
            } else if (typeof style === 'object') {
                Object.assign(directionRef.current.style, style);
            }
        }
    }, [t]);
    return directionRef;
}