// IMPORTING LIBRARIES
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from "react-i18next";

// IMPORTING IMAGES/ICONS
import { faAt } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

// IMPORTING COMPONENTS
import BackToTopButton from '../Buttons/BackToTopBtn/BackToTopBtn'

// IMPORTING STYLE
import "./footer.css";

function Footer() {
  const { t } = useTranslation();
  return (
    <div className='footer'>
      {/* <div className="back-to-top-btn">
        <BackToTopButton
          fontSize={50}
        />
      </div> */}
      <div className='content'>
        <div className='column'>
          <h3>{t("components.footer.quicklinks")}</h3>
          <div className='quick-links'>
            <NavLink className="footer__navlink" to="/">{t("components.nav.pages.home")}</NavLink>
            <NavLink className="footer__navlink" to="services" >{t("components.nav.pages.services")}</NavLink>
            <NavLink className="footer__navlink" to="universities" >{t("components.nav.pages.universities")}</NavLink>
            <NavLink className="footer__navlink" to="about" >{t("components.nav.pages.aboutus")}</NavLink>
            <div className="break"></div>
            <NavLink className="footer__navlink" to="contact" >{t("components.nav.pages.contactus")}</NavLink>
            <NavLink className="footer__navlink" to="agent" >{t("components.nav.pages.beouragent")}</NavLink>
            <div className="icons">
              <div className="link__container">
                <NavLink className="footer__navlink" to={"https://www.facebook.com/profile.php?id=100063771751084"} target="_blank">
                  <FontAwesomeIcon icon={faFacebookF} className='icon' />
                </NavLink>
              </div>
              <NavLink className="footer__navlink" to={"https://wa.me/+905342389943"} target="_blank">
                <FontAwesomeIcon icon={faWhatsapp} className='icon' />
              </NavLink>
              <div className="link__container">
                <NavLink className="footer__navlink" to={"https://www.instagram.com/bn_studentservice"} target="_blank">
                  <FontAwesomeIcon icon={faInstagram} className='icon' />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className='column'>
          <h3>{t("components.footer.reachus.title")}</h3>
          <div className='reach-us'>
            <div className='link__container'>
              <NavLink className="footer__navlink" to="https://maps.app.goo.gl/RS1aTCth2ghZ55xY6" target="_blank">
                {t("components.footer.reachus.address")}
              </NavLink>
            </div>
            <div className='link__container'>
              <FontAwesomeIcon icon={faAt} className='icon' />
              <NavLink className="footer__navlink" to={"mailto:bnstudentservices@gmail.com"} target="_blank">
                bnstudentservices@gmail.com
              </NavLink>
            </div>
            <div className='link__container'>
              <FontAwesomeIcon icon={faPhone} className='icon' />
              <NavLink className="footer__navlink phone" to="tel:+90 534 238 99 43">
                +90 534 238 99 43
              </NavLink>
            </div>
          </div>

        </div>
        <div className='column'>
          <div className='map'>
            <iframe title="BN Address Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3009.7113495806652!2d28.97367272595791!3d41.0315706213473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab9dffd2fffff%3A0x11d252b3a19a7391!2zxLBzdGFuYnVsIEJleW_En2x1IMSwxZ8gTWVya2V6aQ!5e0!3m2!1sen!2str!4v1714770820125!5m2!1sen!2str" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
      <div className='copyright'>
        {new Date().getFullYear()}  {t("components.footer.copyright")}
      </div>
    </div>
  )
}
export default Footer;