import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  sliderBackground,
  home_search_button,
  home_contact_form_points
} from "../../Common/Imgs/Image";
import { school, rating, working } from "../../Common/Icon/Icon";
import Title from "../../Common/components/Title/Title";
import IconCard from "./component/IconCard";
import ContactForm from "../../Common/components/ContactForm/ContactForm";
import DropDownInput from "../../Common/components/DropDownInput/DropDownInput";
import StudentServices from "../../Common/components/StudentServices/StudentServices";
import "./home.css";
import { NavLink } from "react-router-dom";
import Carousel from "../../Common/components/Carousel/Carousel";

import {
  uni1,
  uni2,
  uni3,
  uni4,
  uni5,
  uni6,
  uni7,
  uni8,
  uni9,
  uni10,
  uni11,
  uni12,
  uni13,
  uni14,
  uni15,
  uni16,
  uni17,
  uni18,
} from '../../Common/Imgs/Image'

const UniIcons = [
  uni1,
  uni2,
  uni3,
  uni4,
  uni5,
  uni6,
  uni7,
  uni8,
  uni9,
  uni10,
  uni11,
  uni12,
  uni13,
  uni14,
  uni15,
  uni16,
  uni17,
  uni18,
]

const iconInfo = [
  {
    src: school,
    title: "pages.home.title2.subtitle1",
    num: "10+",
  },
  {
    src: rating,
    title: "pages.home.title2.subtitle2",
    num: "300+",
  },
  {
    src: working,
    title: "pages.home.title2.subtitle3",
    num: "2+",
  },
];


function Home() {
  const { t } = useTranslation();
  const studyLevel = ['Bachelor', 'Master', 'PhD'];
  const [selectedStudyLevel, setSelectedStudyLevel] = useState(studyLevel[0]);
  const studyMajor = [
    'Computer Science',
    'Electrical Engineering',
    'Mechanical Engineering',
    'Civil Engineering',
    'Business Administration',
    'Finance',
    'Economics',
    'Psychology',
    'Biology',
    'Chemistry',
    'Physics',
    'Mathematics',
    'Political Science',
    'Sociology',
    'History',
    'Philosophy',
    'English Literature',
    'Environmental Science',
    'Architecture',
    'Graphic Design',
  ];

  const [selectedStudyMajor, setSelectedStudyMajor] = useState(studyMajor[0]);
  const studyLanguage = ['English', 'Turkish'];
  const [selectedStudyLanguage, setSelectedStudyLanguage] = useState(studyLanguage[0]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    document.title = t("components.nav.pages.home") + " | BN STUDENTS";
    window.scrollTo(0, 0);
  }, [t]);


  const subHeaders = [t("pages.home.title1.subtitle1"), t("pages.home.title1.subtitle2"), t("pages.home.title1.subtitle3")];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsFading(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % subHeaders.length);
        setIsFading(false);
      }, 100);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [subHeaders.length]);


  const collapsedHeaderText = [
    <p className="home-page_intro--collapsed__text" key="1" >{t("pages.home.title1.subtitle1")}</p>,
    <p className="home-page_intro--collapsed__text" key="2" >{t("pages.home.title1.subtitle2")}</p>,
    <p className="home-page_intro--collapsed__text" key="3" >{t("pages.home.title1.subtitle3")}</p>
  ]
  return (
    <div className="home-page">

      <div className="home-page_intro">
        {windowWidth > 768 ?
          (<div className="home-page-intro_upper">
            <div className="home-page-intro_left">
              <div className="text-wrapper">
                <h1>
                  {t("pages.home.title1.title")}
                </h1>
                <p className={`text ${isFading ? 'hidden' : ''}`}>
                  {subHeaders[currentIndex]}
                </p>
                <div className="home-intro__button-container">
                  <NavLink className="home-intro__button" to="/contact">{t("pages.home.acceptanceBtn")}</NavLink>
                </div>
              </div>
            </div>
            <div className="home-page-intro_right">
              <img src={sliderBackground} alt="students" className="home-intro__photo" />
              <div className="home-page__diagonal-line"></div>
            </div>
          </div>)
          :
          <div className="home-page_intro--collapsed">
            <img src={sliderBackground} alt="students" />
            <div className="text-slider">
              <Carousel divs={collapsedHeaderText} dotCount={3} dotStyle={{ padding: '1rem 1rem' }} Loop DragFree={false} />
              <div className="home-intro__button-container">
                <NavLink className="home-intro__button" to="/contact">{t("pages.home.acceptanceBtn")}</NavLink>
              </div>
            </div>
          </div>
        }

        <div className="home-page-intro-lower">
          <div className="home-page-intro-lower_left">
          </div>
          <div className="home-page-intro-lower_right">
            <div className="home-page-intro-lower-right_title">
              <Title content={t("pages.home.title2.title")} />
            </div>
            <div className="intro-icon__card">
              {iconInfo.map((item) => (
                <IconCard
                  key={item.title}
                  src={item.src}
                  text={t(item.title)}
                  num={item.num}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="home-page_search__background-color">
        <div className="home-page_search-section">
          <div className="search-section__partition">
            <div className="search-partition__title">{t("pages.home.searchSection.level")}</div>
            <DropDownInput
              options={studyLevel}
              selectedOption={selectedStudyLevel}
              onSelect={setSelectedStudyLevel}
            />
          </div>
          <div className="search-section__partition">
            <div className="search-partition__title">{t("pages.home.searchSection.major")}</div>
            <DropDownInput
              options={studyMajor}
              selectedOption={selectedStudyMajor}
              onSelect={setSelectedStudyMajor}
            />
          </div>
          <div className="search-section__partition">
            <div className="search-partition__title">{t("pages.home.searchSection.language")}</div>
            <DropDownInput
              options={studyLanguage}
              selectedOption={selectedStudyLanguage}
              onSelect={setSelectedStudyLanguage}
            />
          </div>
          <div className="search-section__partition">
            <button className="home-page__search-button"> <img src={home_search_button} alt="search" /><span>{t("pages.home.searchSection.button")}</span> </button>
          </div>
        </div>
      </div>
      <StudentServices />
      <div className="carousel_unies">
        <Carousel icons={UniIcons} dotCount={6} dotStyle={{ padding: '4rem 4rem' }} />
      </div>
      <div className="home-page-question">
        <div className="home-page-question__background">
          <div className="home-page-question__questions">
            <h1>{t("pages.home.formSection.title")} <br />{t("pages.home.formSection.title2")}</h1>
            <h2>{t("pages.home.formSection.subtitle")}</h2>
            <ul>
              <li> <img src={home_contact_form_points} alt='question list' />{t("pages.home.formSection.question1")}</li>
              <li> <img src={home_contact_form_points} alt='question list' />{t("pages.home.formSection.question2")}</li>
              <li> <img src={home_contact_form_points} alt='question list' />{t("pages.home.formSection.question3")}</li>
              <li> <img src={home_contact_form_points} alt='question list' />{t("pages.home.formSection.question4")}</li>
              <li> <img src={home_contact_form_points} alt='question list' />{t("pages.home.formSection.question5")}</li>
              <li><img src={home_contact_form_points} alt='question list' />{t("pages.home.formSection.question6")}</li>
            </ul>
          </div>
          <div className="home-page-question__contact-form">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;