import React from 'react'
import { PhoneInput } from 'react-international-phone';
import './phone-input_style.css';
export default function PhoneInputParser({ placeholder, name, value, onChange, required }) {
    return (
        <PhoneInput
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            required={required}
        />
    )
}
