import { Route, Routes, HashRouter } from "react-router-dom";

import Router from "./Router/Router";
import Home from "./Pages/Home/Home";
import Services from "./Pages/Services/Services";
import Universities from "./Pages/Universities/Universities";
import About from "./Pages/AboutUs/About";
import Contact from "./Pages/Contact/Contact";
import BeOurAgent from "./Pages/BeOurAgent/BeOurAgent";
import UniPage from "./Pages/Universities/Components/UniPage/UniPage";

// IMPORTING STYLE
import './mainStyle.css'

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Router />}>
          <Route index element={<Home />} />
          <Route path="services" element={<Services />} />
          <Route path="universities" element={<Universities />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="agent" element={<BeOurAgent />} />
          <Route path="universities/:country/:slug" element={<UniPage />} />
        </Route>
      </Routes>
    </HashRouter >
  )
}

export default App;