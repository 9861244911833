import React from 'react'
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import useDirectionBasedStyle from '../../../../hooks/DirectionBasedStyle/useDirectionBasedStyle';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./hoverCard.css";

export default function HoverCard({ img, title, content, link, linkTitle }) {
    const { t } = useTranslation();
    let styleRef = useDirectionBasedStyle(true);
    return (
        <div className="hover-card">
            {img ?
                <div className="img-container">
                    <img src={img} />
                </div>
                :
                null
            }
            <h1>{title} </h1>
            <p>{content} </p>
            {link ?
                <NavLink to={link} className="hover-card__link">
                    {linkTitle}
                    <div ref={styleRef}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                </NavLink>
                :
                null
            }
        </div >
    )
}