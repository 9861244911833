import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import UniesTR from './Sections/UniesTR/UniesTR';
import UniesCY from './Sections/UniesCY/UniesCY';
import UniesRU from './Sections/UniesRU/UniesRU';

import "./universities.css";

function Universities() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("components.nav.pages.universities") + " | BN STUDENTS";
    window.scrollTo(0, 0);
  }, [t]);
  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="universities__main-container">
      <div className="tabs-container">
        <div
          onClick={() => handleTabClick(1)}
          className={`tab-button ${activeTab === 1 ? 'tab-button-active' : ''}`}
        >
          <span>{t("pages.universities.tabs.turkiye")}</span>
        </div>
        <div
          onClick={() => handleTabClick(2)}
          className={`tab-button ${activeTab === 2 ? 'tab-button-active' : ''}`}
        >
          <span>{t("pages.universities.tabs.northCyprus")}</span>
        </div>
        <div
          onClick={() => handleTabClick(3)}
          className={`tab-button ${activeTab === 3 ? 'tab-button-active' : ''}`}
        >
          <span>{t("pages.universities.tabs.russia")}</span>
        </div>
      </div>
      <div className="content-container">
        {activeTab === 1 && <div><UniesTR /></div>}
        {activeTab === 2 && <div><UniesCY /></div>}
        {activeTab === 3 && <div><UniesRU /></div>}
      </div>
    </div>
  );
}
export default Universities;