import "./iconCard.css";

function IconCard(props) {
  return (
    <div className="icon-card">
      <img src={props.src} alt={props.title} />
      <span>{props.text}</span>
      <h1>{props.num}</h1>
    </div>
  );
}

export default IconCard;
