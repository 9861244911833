import React from 'react'
import { useTranslation } from "react-i18next";
import Title from '../../../../Common/components/Title/Title';
import UniCards from '../../Components/UniCards/UniCards';

import "./uniesRU.css";

export default function UniesRU() {
    const { t } = useTranslation();
    return (
        <>
            <div className="universities-ru__title-container">
                <Title title={t("pages.universities.tabs.russia")} />
            </div>
            <UniCards country='ru' />
        </>
    )
}
