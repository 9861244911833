import React from 'react'
import { useTranslation } from "react-i18next";
import Title from '../../../../Common/components/Title/Title';
import UniCards from '../../Components/UniCards/UniCards';

import "./uniesTR.css";

export default function UniesTR() {
    const { t } = useTranslation();
    return (
        <>
            <div className="universities-tr__title-container">
                <Title title={t("pages.universities.tabs.turkiye")} />
            </div>
            <UniCards country='tr' />
        </>
    )
}