import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import LangDropDown from "./LangDropDown/LangDropDown";
import { logo, apply_now, arabic_flag } from "../Imgs/Image";
import "./nav.css";
import { navIcon } from "../Imgs/Image";

function NavBar() {
    const { t, i18n } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => setIsOpen(!isOpen);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const changeLang = (lang) => {
        i18n.changeLanguage(lang);
        toggleMenu();
    }

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        const closeMenuOnClick = (e) => {
            // Assuming 'nav-link' is a class applied to your NavLink components
            // if (e.target.closest('.navlink')) {
            //     setIsOpen(false);
            // }
        };

        document.addEventListener('click', closeMenuOnClick);
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
            document.removeEventListener('click', closeMenuOnClick);
        };
    }, []);

    useEffect(() => {
        const currentLanguageCode = Cookies.get('i18next') || 'en';
        document.body.dir = currentLanguageCode === 'ar' ? 'rtl' : 'ltr';
    }, [t])

    return (
        <nav className="header__nav">
            <div className="header__img">
                {windowWidth < 1028 && (
                    <div className="collapsed-navbar">
                        <NavLink className="apply-btn" to="contact"><img src={apply_now} /><span>{t("components.nav.upper.apply")}</span></NavLink>
                        <button
                            className="nav_door_button"
                            onClick={toggleOpen}>
                            <div className={`nav-button ${isOpen ? "open" : ""}`}></div>
                        </button>
                        <ul
                            className={`${isOpen ? "open" : ""} `}>
                            <li><NavLink className="navlink" to="/" end onClick={toggleMenu}>{t("components.nav.pages.home")}</NavLink></li>
                            <li><NavLink className="navlink" to="services" onClick={toggleMenu}>{t("components.nav.pages.services")}</NavLink></li>
                            <li><NavLink className="navlink" to="universities" onClick={toggleMenu}>{t("components.nav.pages.universities")}</NavLink></li>
                            <li><NavLink className="navlink" to="about" onClick={toggleMenu}>{t("components.nav.pages.aboutus")}</NavLink></li>
                            <li><NavLink className="navlink" to="contact" onClick={toggleMenu}>{t("components.nav.pages.contactus")}</NavLink></li>
                            <li><NavLink className="navlink" to="agent" onClick={toggleMenu}>{t("components.nav.pages.beouragent")}</NavLink></li>
                            <li><div className="navlink" onClick={() => changeLang('ar')}>{t("components.language-drop-down-menu.ar")}</div></li>
                            <li><div className="navlink" onClick={() => changeLang('en')}>{t("components.language-drop-down-menu.en")}</div></li>
                        </ul>
                    </div>
                )}

                <div className="logo">
                    <NavLink to="/">
                        <img src={logo} />
                    </NavLink>
                </div>
                <div className="header__placeHolder">
                    <div className="header-placeHolder__buttons">
                        {windowWidth > 1028 && (
                            <NavLink className="apply-btn" to="contact"><img src={apply_now} /><span>{t("components.nav.upper.apply")}</span></NavLink>)}
                        {windowWidth > 1028 && (
                            <LangDropDown languages={['ar', 'en']}></LangDropDown>
                        )}
                    </div>
                </div>

                <NavLink className="wa-icon__container" to={"https://wa.me/+905342389943"} target="_blank">
                    <FontAwesomeIcon icon={faWhatsapp} className='wa-icon' />
                </NavLink>

            </div>
            {windowWidth > 1028 && (
                <div className="header__nav">
                    <div className="header__navlinks">
                        <NavLink className="navlink" to="/" end >{t("components.nav.pages.home")}</NavLink>
                        <NavLink className="navlink" to="services">{t("components.nav.pages.services")}</NavLink>
                        <NavLink className="navlink" to="universities">{t("components.nav.pages.universities")}</NavLink>
                        <NavLink className="navlink" to="about">{t("components.nav.pages.aboutus")}</NavLink>
                        <NavLink className="navlink" to="contact">{t("components.nav.pages.contactus")}</NavLink>
                        <NavLink className="navlink" to="agent">{t("components.nav.pages.beouragent")}</NavLink>
                    </div>
                </div>
            )}
        </nav>
    )
}

export default NavBar;
